import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import BagActivation from "./components/walletactivation/walletactivation.component.jsx";
import BagDetails from "../src/components/bagdetails/bagdetails.component.jsx";
import ReportLost from "../src/components/bagreportlost/reportlost.component.jsx";
import BagPoster from "../src/components/bagposter/bagposter.component.jsx";
import ReportFound from "../src/components/reportfound/reportfound.component.jsx";
import EditReportLost from "../src/components/bagreportlost/editreportlost/editreportlost.component.jsx";
import Home from "../src/components/home/home.component.jsx";
import Navbar from "../src/components/header/header.component.jsx";
import FoundDetails from "../src/components/reportfound/founddetails/founddetails.component.jsx";
import ProtectedRoute from "./ProtectedRoute.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let usertoken = readCookie("token");
  const uid = localStorage.getItem("uid");
  const dashboardRoute = "/dashboard/" + uid;
  const [isAuth, setIsAuth] = useState(usertoken !== null ? true : false);
  useEffect(() => {
    setIsAuth(usertoken !== null ? true : false);
  }, [isAuth]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HashRouter>
          <div className="App">
            {/* <Navbar /> */}
            <Switch>
              <ProtectedRoute
                path={"/wallet/edit-report-lost/:tag_number"}
                component={EditReportLost}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/wallet/report-lost/:tag_number"}
                component={ReportLost}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/wallet-poster/:tag_number"}
                component={BagPoster}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/wallet-details/:tag_number"}
                component={BagDetails}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/wallet-activation/:tag_number"}
                component={BagActivation}
                isAuth={isAuth}
              />
              <Route
                path={"/wallet/report-found/:tag_number"}
                render={(props) => <FoundDetails {...props} />}
              />
              <Route
                path={"/scan/:tag_number"}
                render={(props) => <ReportFound {...props} />}
              />
              <Route path={"/"}>
                <Home />
              </Route>
            </Switch>
          </div>
        </HashRouter>
      </LocalizationProvider>
    </div>
  );
}

export default App;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./bagdetails.component.css";
// import Bagphoto from "../../assets/bagpack.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
// import { Menu, TextField } from "@mui/material";
import {
  TextField,
  // Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Navbar from "../header/header.component";
import axios from "axios";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { switchUrls } from "../../api/index.js";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import imageCompression from "browser-image-compression";
import setCanvasPreview from "../../setcanvasPreview.js";
import Footer from "../footer/footer.component.jsx";
import Loader from "../../assets/loader.gif";

const baseURL = switchUrls("wallet");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

export default class BagDetails extends React.Component {
  constructor(props) {
    super(props);
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      imageUrl: null,
      // bag_imagelocation: null,
      // bag_imagename: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "",
      description: "",
      date_of_purchase: "",
      wallet_color: "",
      wallet_brand: "",
      warranty_period: "",
      selectedOption: "Day",
      snackbaropen: false,
      snackbarmsg: "",
      sidebar: true,
      subMenu: true,
      message: "",
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/wallet.png",
      imagemodal: false,
      crop: "",
      uploaderror: false,
      Wallet_imagelocation: null,
      imageName: "",
      showLoader: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
  }
  cropsetup(e) {
    // console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    // console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    // console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob(async (blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      const imageDimension = await this.imageSize(file);
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 2,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      });
      console.log(
        "image size after compression:",
        compressedFile.size / (1024 * 1024)
      );

      this.setState({
        selectedImage: compressedFile,
        imageUrl: URL.createObjectURL(compressedFile),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
        showLoader: false,
      });
    });
  }

  componentDidMount() {
    axios
      .get(
        `${baseURL}/api/wallet/getWalletDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          // bag_imagelocation: response.data.bag_imagelocation,
          // bag_imagename: response.data.bag_imagename,
          tag_number: response.data.tag_number,
          tag_status: response.data.tag_status,
          valuable_type: response.data.valuable_type,
          wallet_color: response.data.wallet_color,
          wallet_brand: response.data.wallet_brand,
          description: response.data.description,
          date_of_purchase: response.data.date_of_purchase,
          warranty_period: response.data.warranty_period,
          selectedOption: response.data.warranty_period.split(" ")[1],
          Wallet_imagelocation: response.data?.wallet_imagelocation,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });
  }

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }

  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }

  async handleChange(event) {
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      console.log("image original size:", sizeinmb);
      this.setState({ imagemodal: true });
      this.setState({ message: "" });
      this.setState({
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
        isuploading: true,
        imageName: event.target.files[0].name,
      });
    } else {
      console.log("failed");
    }
  }
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    // console.log(e.target.value, "warranty value");
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  deleteimage = (e) => {
    axios
      .post(`${baseURL}/api/wallet/deleteWalletimage`, this.state)
      .then((response) => {
        // console.log(response?.data);
        if (response.data?.status) {
          this.setState({
            imageUrl: "",
            Wallet_imagelocation: null,
            snackbaropen: true,
            snackbarmsg: "Image removed",
          });
        } else {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
          });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });
  };
  handleSelectChange = (event) => {
    // console.log(event.target.value, this.state.warranty_period, "event");
    this.setState({
      selectedOption: event.target.value,
      warranty_period: `${this.state.warranty_period?.split(" ")[0]} ${
        event.target.value
      }`,
    });
  };
  handleChangeWarranty = (e) => {
    // console.log(this.state.selectedOption, "selected option");
    this.setState({
      warranty_period: `${e.target.value} ${this.state.selectedOption}`,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ showLoader: true });
    // console.log(this.state);
    axios
      .post(
        `${baseURL}/api/wallet/editwalletDetails/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        // console.log(response);
        if (response.data === "Wallet Details Updated Successfully") {
          if (this.state?.selectedImage != null) {
            const fd = new FormData();

            fd.append("image", this.state.selectedImage);
            fd.append("imageName", this.state.imageName);
            fd.append("tag_number", this.props.match.params.tag_number);
            axios
              .post(`${baseURL}/api/wallet/walletimageupload`, fd, {
                headers: {
                  "Content-Type": "multipart/form-data", // Set the correct content type
                },
              })
              .then((res) => {
                // console.log(res);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Wallet image upload Successfully",
                });
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              })
              .catch((error) => {
                console.error(error.message);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Wallet image upload failed",
                  showLoader: false,
                });
              });
          } else {
            window.location.href = "/dashboard/" + localStorage.getItem("uid");
          }
        } else if (response.data === "Wallet Details Update Failed") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: response.data,
            showLoader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ showLoader: false });
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Wallet Details Update Failed",
          showLoader: false,
        });
      });
  };

  render() {
    const {
      bag_imagelocation,
      imageUrl,
      tag_number,
      date_of_purchase,
      warranty_period,
      description,
      wallet_color,
      wallet_brand,
      selectedOption,
      sidebar,
      subMenu,
      Wallet_imagelocation,
      imageUrlvector,
      imagemodal,
      uploaderror,
      crop,
      message,
    } = this.state;
    const today = () => {
      const dtToday = new Date();
      const year = dtToday.getFullYear();
      const month = (dtToday.getMonth() + 1).toString().padStart(2, "0");
      const day = dtToday.getDate().toString().padStart(2, "0");

      const today = `${day}-${month}-${year}`;
      return today;
    };

    // console.log(today(), "today");
    return (
      <>
        <Navbar />
        <div className="keydetails-main-wrapper">
          <div className="keydetails-left-wrapper">
            <div className="keydetails-sidebar-toggle-btn-wrapper">
              <button
                className="keydetails-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "keydetails-sidebar-container-hide"
                  : "keydetails-sidebar-container"
              }
            >
              <button
                className="keydetails-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "keydetails-sidebar-menu-ul-hide"
                    : "keydetails-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="keydetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="keydetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="keydetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="keydetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + this.props.match.params.tag_number;
                }}
              >
                ACTIVATE TAG
              </button>

              <button
                className="keydetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + this.props.match.params.tag_number;
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>
        </div>
        <div className="bag-container">
          <div className="background-image">
            <div className="bag-activation-container">
              <p>
                <strong>Wallet Details</strong>
              </p>

              <div className="bag-activation-form-container">
                <form
                  onSubmit={this.submitHandler}
                  className="bag-activation-form"
                >
                  <div className="editPetDetails-user-img">
                    {imageUrl?.length ? (
                      <>
                        <img
                          alt="imageurl"
                          src={imageUrl}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : Wallet_imagelocation ? (
                      <>
                        <img
                          alt="imagelocation"
                          src={Wallet_imagelocation}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          alt="imageyrlvector"
                          src={imageUrlvector}
                          className="editPetDetails-image"
                        />
                      </>
                    )}
                    <div className="editPetDetails-edit-btn-wrap">
                      <label
                        htmlFor="upload"
                        className="editPetDetails-edit-button-image"
                        style={{ cursor: "pointer" }}
                      >
                        <MdIcons.MdEdit /> Change
                      </label>

                      <input
                        id="upload"
                        className="editPetDetails-input-user-img"
                        type="file"
                        onChange={this.handleChange}
                      />
                      {Wallet_imagelocation ? (
                        <>
                          <label
                            className="editPetDetails-delete-button-image "
                            onClick={this.deleteimage}
                          >
                            <AiIcons.AiFillDelete /> Remove
                          </label>
                        </>
                      ) : null}
                    </div>
                    <div
                      className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                      style={{ gap: "6px" }}
                    >
                      <img
                        alt="qr"
                        src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <p style={{ marginTop: "15px" }}>
                        <b>
                          {tag_number && tag_number !== "-"
                            ? tag_number
                            : "N/A"}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="bagactivation-label-container">
                      <TextField
                        type="date"
                        max={today()}
                        variant="outlined"
                        // className="bagactivation-input-tag"
                        label="Date of Purchase"
                        name="date_of_purchase"
                        value={date_of_purchase.substring(0, 10)}
                        onChange={this.changeHandler}
                      />
                    </div>

                    <div className="bagactivation-label-container">
                      {/* <label>Description</label> */}
                      <TextField
                        type="text"
                        // className="keydetails-right-form-inputs"
                        variant="outlined"
                        label="Description"
                        name="description"
                        value={description}
                        onChange={this.changeHandler}
                      />
                    </div>

                    <div className="bagactivation-warrantylabel-container">
                      {/* <label>Warranty Period (in months)</label> */}
                      <TextField
                        type="number"
                        // className="keydetails-right-form-inputs"
                        variant="outlined"
                        maxLength="20"
                        label="Warranty Period"
                        name="warranty_period"
                        value={warranty_period.split(" ")[0]}
                        onChange={this.handleChangeWarranty}
                      />
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{ marginTop: "-7px", marginLeft: "13px" }}
                        >
                          Valuable Type
                        </InputLabel>
                        <Select
                          labelId="valuable-type-label"
                          id="valuable-type-select"
                          required
                          label="Warrant Period"
                          value={selectedOption}
                          onChange={this.handleSelectChange}
                          variant="outlined"
                        >
                          <MenuItem value="Day">Day</MenuItem>
                          <MenuItem value="Month">Month</MenuItem>
                          <MenuItem value="Year">Year</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="bagactivation-label-container">
                      {/* <label>Wallet Colorshs</label> */}
                      <TextField
                        type="text"
                        // className="keydetails-right-form-inputs"
                        variant="outlined"
                        maxLength="20"
                        label="Wallet Color"
                        name="wallet_color"
                        value={wallet_color}
                        onChange={this.changeHandler}
                      />
                    </div>

                    <div className="bagactivation-label-container">
                      {/* <label>Wallet Brand</label> */}
                      <TextField
                        type="text"
                        // className="keydetails-right-form-inputs"
                        variant="outlined"
                        maxLength="20"
                        label="Wallet Brand"
                        name="wallet_brand"
                        value={wallet_brand}
                        onChange={this.changeHandler}
                      />
                    </div>

                    <div className="bagactivation-btn-grp">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                      >
                        SUBMIT
                      </Button>

                      <Button
                        variant="contained"
                        type="button"
                        style={{ width: "100%" }}
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                        className="submit-login bg-white mt-2"
                      >
                        CANCEL
                      </Button>
                    </div>
                    <Snackbar
                      open={this.state.snackbaropen}
                      autoHideDuration={3000}
                      onClose={this.snackbarClose}
                      message={this.state.snackbarmsg}
                      action={[
                        <IconButton
                          key="close"
                          arial-label="Close"
                          color="inherit"
                          onClick={this.snackbarClose}
                        >
                          X
                        </IconButton>,
                      ]}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button
                    onClick={() => {
                      this.setState({ showLoader: true });
                      this.handelimagecrop();
                    }}
                  >
                    Submit
                  </Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>

        {/* Loader */}
        {this.state.showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={Loader}
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

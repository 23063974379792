import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../bagdetails/bagdetails.component.css";
// import Logo from "../../assets/tag8logoblack.png";
// import Bagimage from "../../assets/bagpack.png";
import "font-awesome/css/font-awesome.min.css";
import Card from "@material-ui/core/Card";
import "./bagposter.component.css";
import axios from "axios";
import html2canvas from "html2canvas";
import * as FaIcons from "react-icons/fa";
import * as FcIcons from "react-icons/fc";
import { switchUrls } from "../../api/index.js";
import * as GiIcons from "react-icons/gi";

const baseURL = switchUrls("wallet");

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default class BagPoster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      owner_firstname: "",
      owner_lastname: "",
      valuable_type: "",
      description: "",
      wallet_color: "",
      wallet_brand: "",
      identification_mark: "",
      owner_phone: "",
      owner_email: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      address: "",
      wallet_imagelocation: "",
      active: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    axios
      .get(
        `${baseURL}/api/wallet/getreportlocation/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          address: response.data.data[0].address,
        });
      })
      .catch((error) => {
        console.error(error.message);
      });

    axios
      .get(
        `${baseURL}/api/wallet/getWalletImagePoster/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          wallet_imagelocation: response.data.wallet_imagelocation,
        });
      })
      .catch((error) => {
        console.error(error.message);
      });

    axios
      .get(
        `${baseURL}/api/wallet/getReportLost/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          owner_firstname: response.data.owner_firstname,
          owner_lastname: response.data.owner_lastname,
          valuable_type: response.data.valuable_type,
          description: response.data.description,
          wallet_color: response.data.wallet_color,
          wallet_brand: response.data.wallet_brand,
          identification_mark: response.data.identification_mark,
          owner_phone: response.data.owner_phone,
          owner_email: response.data.owner_email,
          other_noofbags: response.data.other_noofbags,
          lost_text: response.data.lost_text,
          lost_date: response.data.lost_date,
          reward: response.data.reward,
          rewardcurrency: response.data.rewardcurrency,
          lost_timeone: response.data.lost_timeone,
          lost_timetwo: response.data.lost_timetwo,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
        });
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  downloadposter() {
    let elem = document.getElementById("contentToConvert");
    html2canvas(elem, { useCORS: true, allowTaint: true }).then(function (
      canvas
    ) {
      let generatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let a = document.createElement("a");
      a.href = generatedImage;
      a.download = `bag-poster.png`;
      a.click();
    });
  }

  toggleMenu() {
    console.log(this.state.active);

    this.setState({
      active: !this.state.active,
    });
  }

  showOptions() {
    if (this.state.showdiv) {
      document.getElementById("bdiv").style.bottom = "-14.5rem";
      document.getElementById("aup").style.display = "block";
      document.getElementById("adown").style.display = "none";

      this.setState({ showdiv: false });
    } else {
      document.getElementById("bdiv").style.bottom = "0";
      document.getElementById("aup").style.display = "none";
      document.getElementById("adown").style.display = "block";

      this.setState({ showdiv: true });
    }
  }

  render() {
    const {
      owner_firstname,
      owner_lastname,
      wallet_color,
      wallet_brand,
      valuable_type,
      description,
      lost_text,
      owner_phone,
      reward,
      rewardcurrency,
      wallet_imagelocation,
      active,
    } = this.state;

    return (
      <>
        <div className="genie-nav" id="genie-nav">
          <img
            src={"https://storage.googleapis.com/pettag/qr/tag8logoblack.png"}
            alt="logo"
            style={{ width: "5rem" }}
          />

          <button
            className="bag-poster-toggle-btn"
            onClick={() => this.toggleMenu()}
          >
            <GiIcons.GiHamburgerMenu />
          </button>
        </div>

        <div
          className={
            active ? "poster-utility" : "poster-utility poster-utility-hide"
          }
        >
          {/* <button className="poster-utility-btns" onClick={this.downloadposter}>
            Download
          </button> */}

          <a
            className="poster-utility-btns"
            href={
              "/#/wallet/edit-report-lost/" + this.props.match.params.tag_number
            }
          >
            Edit
          </a>

          <a
            onClick={() => {
              window.location.href =
                "/dashboard/" + localStorage.getItem("uid");
            }}
            className="poster-utility-btns"
          >
            Go To My Tags
          </a>
        </div>

        <div id="contentToConvert" className="pb-3">
          <Card className="mb-5 key-mat-card">
            <div
              className="lost-info-detail-header bg-gray"
              style={{ textAlign: "center" }}
            >
              <p>
                <p className="header-font-key header-text-key ">LOST Wallet!</p>
                {/* <p className="header-font-key  lost-data-header"> */}
                {/* keys */}
                {/* {product_category == "-" ? "VALUABLE" : "KEYS"}{" "} */}
                {/* </p> */}
              </p>
            </div>

            <div className="row  flex">
              <div className="col-md-5 pr-5 img-container colorw">
                <div className="main-img2">
                  <img
                    src={
                      wallet_imagelocation
                        ? `data:image/png;base64,${wallet_imagelocation}`
                        : "https://storage.googleapis.com/pettag/qrtagdev/assets/wallet.png"
                    }
                    alt="valuable"
                    className="mat-card-image2"
                  />
                </div>
              </div>

              {/* <div className="col-md-2 colorw"> */}
              {/* <span className="reportfound-message">
                <span className="reportfound-message-header">
                  This valuable belongs to
                </span>

                <p className="reportfound-message-content">
                  <span className="reportfound-message-content-icon">
                    <FaIcons.FaUserCircle />
                  </span>

                  <span className="reportfound-message-content-name">
                    {owner_firstname && owner_firstname !== "-"
                      ? owner_firstname
                      : null}{" "}
                    {owner_lastname && owner_lastname !== "-"
                      ? owner_lastname
                      : null}
                  </span>
                </p>

                <p className="reportfound-message-ownerid">
                  Owner ID :<span>{this.props.match.params.tag_number}</span>
                </p>
              </span> */}
              {/* {lost_text ? (
                  <div className="poster-info-wrapper">
                    <p>Lost Message</p>
                    <p style={{ fontSize: "30px", fontWeight: "bold" }}>
                      {toTitleCase(lost_text)}
                    </p>
                  </div>
                ) : null} */}
              {/* </div> */}
            </div>
            <div className="poster-info-wrapper">
              {description ? (
                // <div
                //   style={{
                //     display: "flex",
                //     flexDirection: "column",
                //     justifyContent: "center",
                //     alignItems: "center",
                //   }}
                // >

                // </div>
                <>
                  {" "}
                  <p className="lost-info-text-Desc">WALLET DESCRIPTION</p>
                  <p className="lost-info-text-address">
                    {toTitleCase(description)}
                  </p>
                </>
              ) : null}

              {wallet_color || wallet_brand ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="lost-info-text">WALLET COLOR</p>
                  <p className="lost-info-text-address">{wallet_color}</p>
                </div>
              ) : null}

              {wallet_brand ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="lost-info-text">WALLET BRAND</p>
                  <p className="lost-info-text-address">{wallet_brand}</p>
                </div>
              ) : null}
            </div>
            {/* <div className="key-lost-detail" id="bdiv">
            <i
              className="fa fa-angle-up"
              id="aup"
              aria-hidden="true"
              onClick={this.showOptions.bind(this)}
            ></i>

            <i
              className="fa fa-angle-down"
              id="adown"
              style={{ display: "none" }}
              aria-hidden="true"
              onClick={this.showOptions.bind(this)}
            ></i>

            <p
              style={{
                fontSize: "26px",
                fontFamily: "ABeeZee",
              }}
            >
              Help me return home
            </p>

           
          </div> */}
            <div className="poster-msg-wrapper-cover">
              <div className="poster-msg-wrapper">
                <span className="poster-msg">
                  <p>PLEASE HELP ME FIND MY</p>
                  <p>WALLET</p>
                </span>

                <span className="poster-owner-details">
                  {owner_firstname && owner_lastname ? (
                    <p className="poster-owner-name">
                      Call : {owner_firstname} {owner_lastname}
                    </p>
                  ) : null}

                  {owner_phone ? (
                    <p className="poster-owner-phone">
                      <FcIcons.FcCellPhone />
                      {owner_phone}
                    </p>
                  ) : null}
                </span>
              </div>

              {rewardcurrency && reward ? (
                <div className="poster-reward">
                  <p className="poster-reward-title">REWARD</p>

                  <p
                    className="poster-reward-currency"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {rewardcurrency === "Rupees" ? (
                      <FaIcons.FaRupeeSign />
                    ) : (
                      rewardcurrency
                    )}

                    {reward}
                  </p>
                </div>
              ) : null}
            </div>
          </Card>
        </div>
      </>
    );
  }
}

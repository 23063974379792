import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Logo from "../../../assets/tag8logoblack.png";
// import BagLogo from "../../../assets/bagpack.png";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "../founddetails/founddetails.component.css";
import axios from "axios";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";

export default class FoundDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      owner_firstname: "",
      owner_lastname: "",
      valuable_type: "",
      description: "",
      identification_mark: "",
      owner_phone: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      address: "",
      bag_imagelocation: "",
    };
  }

  componentDidMount() {
    axios
      .get(`/api/bag/getreportlocation/` + this.props.match.params.tag_number)
      .then((response) => {
        console.log(response);
        this.setState({
          address: response.data.data[0].address,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/api/bag/getBagDetails/` + this.props.match.params.tag_number)
      .then((response) => {
        console.log(response);
        this.setState({
          bag_imagelocation: response.data.bag_imagelocation,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/api/bag/getReportLost/` + this.props.match.params.tag_number)
      .then((response) => {
        console.log(response);
        if (response.data === "Bag is not reported lost") {
          window.alert(response.data);
          window.location.href = "/scan/" + this.props.match.params.tag_number;
        } else {
          this.setState({
            owner_firstname: response.data.owner_firstname,
            owner_lastname: response.data.owner_lastname,
            valuable_type: response.data.valuable_type,
            description: response.data.description,
            identification_mark: response.data.identification_mark,
            owner_phone: response.data.owner_phone,
            lost_text: response.data.lost_text,
            lost_date: response.data.lost_date,
            reward: response.data.reward,
            rewardcurrency: response.data.rewardcurrency,
            lost_timeone: response.data.lost_timeone,
            lost_timetwo: response.data.lost_timetwo,
            latitude: response.data.latitude,
            longitude: response.data.longitude,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const {
      bag_imagelocation,
      owner_firstname,
      owner_lastname,
      valuable_type,
      description,
      identification_mark,
      owner_phone,
      reward,
      rewardcurrency,
      lost_date,
      address,
    } = this.state;
    return (
      <div>
        <div id="contentToConvert" className="py-3">
          <Card className="mb-5 mat-card">
            <div className="row mt-4 text-center">
              <div className="col-md-4 text-center">
                {bag_imagelocation ? (
                  <img
                    src={bag_imagelocation}
                    className="text-center"
                    alt="pet"
                    style={{
                      width: "100%",
                      height: "100px",
                      borderRadius: 50,
                      marginTop: 7,
                    }}
                  />
                ) : (
                  <img
                    src={"https://storage.googleapis.com/pettag/qr/bagpack.png"}
                    alt="photo"
                    className="text-center"
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: 50,
                      marginTop: 7,
                    }}
                  />
                )}
              </div>
              <div className="col-md-8">
                <div className="row mt-5">
                  {owner_phone != "" ? (
                    <div className="col text-center">
                      <p className="header-font lost-info-header mb-5">
                        Contact Number of Key Owner Is
                      </p>
                      <p className="text-center lost-info-text font-weight-bold ">
                        {owner_phone}
                      </p>
                    </div>
                  ) : (
                    <div className="col text-center">
                      <p className="header-font lost-info-header mb-5">
                        This Bag is not reported as Lost
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4 ml-5 marginleftmove">
              <div className="col">
                <p className="header-font lost-info-header">
                  Owner ID: <span>{this.props.match.params.tag_number}</span>
                </p>
              </div>
              <div className="col">
                <p className="header-font lost-info-header">
                  Valuable: <span>{valuable_type}</span>
                </p>
              </div>
            </div>
            {description ? (
              <div className="row mt-4 ml-5 marginleftmove1">
                <p className="header-font lost-info-header ml-3">
                  Bag Description: <span>{description}</span>
                </p>
              </div>
            ) : (
              <div></div>
            )}
            {/* {other_noofkeys ?
                            <div className="row mt-4 ml-5 marginleftmove1">
                                <p className="header-font lost-info-header ml-3">Number Of Keys: <span>{other_noofkeys}</span></p>
                            </div> : <div></div>} */}
            <hr />
            <div className="row text-center">
              <img
                src={"https://storage.googleapis.com/pettag/qr/tag8logoblack.png"}
                className="mb-3 text-center"
                width="100px"
                height="auto"
                alt=""
                style={{ margin: "auto" }}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
